<template>
  <div class="content">
    <div v-if="loading" class="container-fluid">
      <div class="row h-100">
        <div class="col-12 h-100">
          <div
            class="page-wrapper justify-content-center align-items-center h-100"
          >
            <loading />
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar v-else class="w-100" ref="ps">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12" style="height: 100px">
            <page-title
              class="position-sticky top-0"
              style="z-index: 1000; width: 100%"
            />
          </div>
          <div class="col-12">
            <div class="page-wrapper">
              <FilesPageHeader
                :titleItems="title"
                :year="yearFilter"
                :filesList="filesList"
                :downloadAllFilesLoading="downloadAllFilesLoading"
                @onDownloadSelectedClick="onDownloadSelectedClick"
                @changeYear="changeYear"
              />
              <div class="page-wrapper-body">
                <FilesTable
                  :filesList="filteredFilesList"
                  @onSingleFileDownloadClick="onSingleFileDownloadClick"
                  @changeFilter="changeFilter"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

import Loading from '@/components/common/Loading.vue'
import PageTitle from '@/components/common/PageTitle.vue'
import FilesPageHeader from '@/components/buyer/files/FilesPageHeader.vue'
import FilesTable from '@/components/buyer/files/FilesTable.vue'

export default {
  components: { PageTitle, FilesTable, FilesPageHeader, Loading },
  name: 'BuyerFiles',
  data() {
    return {
      supplierId: null,
      query: {},
      title: [
        {
          title: 'Lieferanten',
          count: 0
        },
        {
          title: 'Dateien',
          count: 0
        }
      ],
      yearFilter: '2024',
      loading: false,
      downloadAllFilesLoading: false,

      filesList: []
    }
  },
  computed: {
    ...mapGetters(['getUserOneRole']),
    modelCurrPos() {
      return this.$store.state.currPgState.model
    },
    filteredFilesList() {
      if (this.yearFilter === 'all') {
        return this.filesList
      }

      return this.filesList.filter(file => file.year === this.yearFilter)
    }
  },
  methods: {
    ...mapMutations(['SET_BREADCRUMB']),
    changeYear(data) {
      this.yearFilter = data
    },

    changeFilter({ column, value }) {
      const sortByName = (a, b) => {
        const aFileName = this.getFileNameWithoutExtension(
          a.scanprixBlobName
        ).toLowerCase()
        const bFileName = this.getFileNameWithoutExtension(
          b.scanprixBlobName
        ).toLowerCase()
        return aFileName.localeCompare(bFileName)
      }

      const sortByDate = (a, b) => new Date(a.createdAt) - new Date(b.createdAt)

      if (column === 'name') {
        this.filesList.sort((a, b) =>
          value === 'asc' ? sortByName(a, b) : sortByName(b, a)
        )
      } else if (column === 'date') {
        this.filesList.sort((a, b) =>
          value === 'asc' ? sortByDate(a, b) : sortByDate(b, a)
        )
      }
    },

    getExtensionFromResponseHeader(header) {
      if (header === 'application/zip') {
        return 'zip'
      } else {
        return 'spcz'
      }
    },

    async onSingleFileDownloadClick(payload) {
      try {
        payload.isLoading = true
        const response = await this.$api.downloadSPCZ([payload._id])
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)

        const fileExtension = this.getExtensionFromResponseHeader(
          response.headers['content-type']
        )
        link.download = `${this.getFileNameWithoutExtension(
          payload.scanprixBlobName
        )}.${fileExtension}`

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (err) {
        if (err.response) {
          console.error('Error data:', err?.response?.data?.message)
        }
      } finally {
        payload.isLoading = false
      }
    },

    getFileNameWithoutExtension(payload) {
      const errorFileName = 'invalid_SCPZ_name'
      if (typeof payload !== 'string' || payload.trim() === '') {
        return errorFileName
      }
      const parts = payload.split('/')
      if (parts.length === 0) {
        return errorFileName
      }
      const fileNameWithExtension = parts[parts.length - 1]
      if (!fileNameWithExtension.includes('.')) {
        return errorFileName
      }
      const fileNameParts = fileNameWithExtension.split('.')
      fileNameParts.pop()
      const fileName = fileNameParts.join('.')
      return fileName.trim() === '' ? errorFileName : fileName
    },
    async onDownloadSelectedClick() {
      const selectedFilesList = this.filesList.filter(item => item.isSelected)
      const selectedFilesIdsArray = selectedFilesList.map(item => item._id)

      try {
        this.downloadAllFilesLoading = true
        const response = await this.$api.downloadSPCZ(selectedFilesIdsArray)
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        const fileExtension = this.getExtensionFromResponseHeader(
          response.headers['content-type']
        )
        /* Return file name value if there is only 1 file downloaded  */
        if (fileExtension === 'spcz') {
          link.download = `${this.getFileNameWithoutExtension(
            selectedFilesList[0]?.scanprixBlobName
          )}.${fileExtension}`
        } else {
          link.download = `SCPZs.${fileExtension}`
        }
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (err) {
        if (err.response) {
          console.error('Error data:', err?.response?.data?.message)
        }
      } finally {
        this.downloadAllFilesLoading = false
      }
    },

    async getFilesList() {
      this.loading = true
      try {
        const response = await this.$api.getSPCZ(this.supplierId)
        if (response && response.data?.status === 'success') {
          const filesData = response.data.data.items
          const statisticData = response.data.data.statistics

          this.filesList = filesData.map(item => ({
            ...item,
            isSelected: false,
            isLoading: false
          }))

          this.title = [
            {
              title: 'Lieferanten',
              count: statisticData?.totalSuppliers
            },
            {
              title: 'Dateien',
              count: statisticData?.totalSpczs
            }
          ]
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },

  created() {
    const name = this.$route.query.seller

    this.supplierId = this.$route.query.id

    if (!(name && this.supplierId)) {
      return this.$router.push('/buyer')
    }

    const isSPCZ = this.getUserOneRole('isSPCZUser')

    if (isSPCZ === -1) {
      return this.$router.push('/access-denied')
    }

    const data = [
      {
        title: name
      },
      {
        title: ' / Einkauf'
      }
    ]
    this.SET_BREADCRUMB(data)
    this.getFilesList()
  }
}
</script>
