<template>
  <div class="groups">
    <div class="table_view">
      <table class="w-100">
        <tr class="header">
          <th
            v-for="h in headerList"
            :key="h.id"
            :class="h.classH ? h.classH : ''"
            @click="() => onColumnHeaderClick(h.id)"
          >
            <div
              v-if="h.id === 'selectedCheckbox'"
              class="d-flex align-self-center position-relative"
            >
              <label
                v-if="filesList?.length"
                class="b-contain ps-0 d-flex justify-content-between position-static"
              >
                <input
                  v-model="areAllFilesSelected"
                  type="checkbox"
                  name="active"
                />
                <div
                  class="b-input position-absolute top-50 start-50 translate-middle"
                  :class="areAllFilesSelected ? 'header-checkbox-selected' : ''"
                />
              </label>
            </div>
            <p
              v-else
              class="mb-0 d-flex"
              :class="
                h.id === 'name'
                  ? 'd-flex justify-content-start'
                  : 'justify-content-center'
              "
            >
              {{ h.label }}
              <template v-if="h.id === 'name' || h.id === 'date'">
                <table-arrow
                  v-if="filterData.column === h.id"
                  :name="filterData.value === 'asc' ? 'up' : 'down'"
                />
              </template>
            </p>
          </th>
        </tr>

        <template v-if="filesList?.length">
          <tr
            v-for="(item, idx) in filesList"
            :key="`${idx}`"
            :class="idx % 2 ? 'bg_gray' : 'bg_white'"
          >
            <td class="txt_wrap position-relative" :title="item.isSelected">
              <div class="d-flex align-self-center position-relative">
                <label
                  class="b-contain ps-0 d-flex justify-content-between position-static"
                >
                  <input
                    v-model="item.isSelected"
                    type="checkbox"
                    name="active"
                  />
                  <div
                    class="b-input position-absolute top-50 start-50 translate-middle"
                    :class="
                      areAllFilesSelected ? 'header-checkbox-selected' : ''
                    "
                  />
                </label>
              </div>
            </td>
            <td class="text-start">
              {{ getFileName(item.scanprixBlobName) }}
            </td>
            <td class="txt_wrap cursor-pointer" :title="item.createdAt">
              {{ getDate(item.createdAt) }}
            </td>
            <td>
              <div class="d-flex justify-content-center align-items-center">
                <p
                  class="cursor-pointer single-download-hover m-0"
                  :class="item.isLoading ? 'opacity-50' : 'cursor-pointer'"
                  @click="onSingleFileDownload(item)"
                >
                  Download
                </p>
              </div>
            </td>
          </tr>
        </template>
        <tr v-else class="bg_white">
          <th colspan="4" style="cursor: auto; padding: 20px">
            <h2 style="width: 100%; color: #555555">Keine Dateien verfügbar</h2>
          </th>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import TableArrow from '../../common/TableArrow.vue'

export default {
  components: { TableArrow },

  name: 'FilesTable',
  data() {
    return {
      headerList: [
        { id: 'selectedCheckbox', classH: 'txt_h', label: 'checkbox' },
        { id: 'name', label: 'Bezeichnung' },
        { id: 'date', label: 'Erstellungsdatum', date: true },
        { id: 'fileName', label: 'ScanPrix Datei' }
      ],
      areAllFilesSelected: false,
      filterData: {
        column: '',
        value: ''
      }
    }
  },
  props: {
    filesList: {
      required: true,
      type: Array,
      default: () => []
    }
  },
  computed: {},
  methods: {
    getDate(date) {
      return date ? this.$moment(date).format('DD.MM.YYYY') : null
    },
    onSingleFileDownload(item) {
      if (item.isLoading) return
      this.$emit('onSingleFileDownloadClick', item)
    },
    getFileName(payload) {
      const errorFileName = 'invalid_SCPZ_name.spcz'
      if (typeof payload !== 'string' || payload.trim() === '') {
        return errorFileName
      }
      const parts = payload.split('/')
      if (parts.length === 0) {
        return errorFileName
      }
      const fileNameWithExtension = parts[parts.length - 1]
      if (fileNameWithExtension.trim() === '') {
        return errorFileName
      }
      return fileNameWithExtension
    },
    onColumnHeaderClick(columnId) {
      const validColumns = ['name', 'date']
      if (!validColumns.includes(columnId)) return

      if (this.filterData.column === columnId) {
        this.filterData.value = this.filterData.value === 'asc' ? 'desc' : 'asc'
      } else {
        this.filterData.column = columnId
        this.filterData.value = 'asc'
      }
      this.$emit('changeFilter', this.filterData)
    }
  },
  watch: {
    areAllFilesSelected: function (value) {
      if (value) {
        this.filesList.forEach(item => (item.isSelected = true))
      } else {
        this.filesList.forEach(item => (item.isSelected = false))
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table_view {
  width: 100%;
  margin-bottom: 2em;
  table {
    background-color: #ee7f00;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    table-layout: fixed;
    width: 100px;
  }

  th {
    text-align: center;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
  }

  table td {
    width: 90px;
    padding: 10px;
    font-weight: 700;
    color: #555555;
    text-align: center;
  }

  .bg_gray {
    background-color: #f9f9f9;
  }

  .bg_white {
    background-color: #ffffff;
  }

  .noWrap {
    white-space: nowrap;
  }

  .txt_h {
    width: 130px;
  }

  .txt_wrap {
    max-width: 130px;
    display: table-cell;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1680px) {
  .table_view {
    .txt_wrap {
      max-width: 100px;
    }

    .txt_h {
      width: 100px;
    }

    table td,
    th {
      font-size: 12px;
      padding: 5px;
    }
  }
}

@media screen and (max-width: 1420px) {
  .table_view {
    .txt_wrap {
      max-width: 80px;
    }

    .txt_h {
      width: 80px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .table_view {
    .txt_wrap {
      max-width: 70px;
    }

    .txt_h {
      width: 70px;
    }

    table td,
    th {
      font-size: 10px;
    }
  }
}

.header-checkbox-selected {
  border: 1px solid var(--vme-light-gray) !important;
}

.single-download-hover:hover {
  text-decoration: underline;
}
</style>
