<template>
  <div class="page-wrapper-header d-flex justify-content-between">
    <div class="page-wrapper-title">
      <p class="text-color-65-gray text-fteen mb-0">
        <span>
          <strong> {{ titleItems[0].count }} </strong>
          {{ titleItems[0].title }},
        </span>
        <span>
          <strong> {{ titleItems[1].count }} </strong> {{ titleItems[1].title }}
        </span>
      </p>
    </div>
    <div class="d-flex">
      <div class="page-sorting-items">
        <span class="me-3 text-color-65-gray">Jahr</span>
        <select
          @change="changeYear"
          v-model="sortYear"
          class="form-control sorting-select"
          style="min-width: 85px; margin-right: 20px"
        >
          <option
            v-for="yearOption in yearOptions"
            :key="yearOption.value"
            :selected="yearOption.isSelected"
            :value="yearOption.value"
          >
            {{ yearOption.label }}
          </option>
        </select>
      </div>
      <button
        class="btn btn-primary text-fteen text-center download-button"
        :class="[
          downloadFilesDisabled ? 'download-disabled disabled' : '',
          downloadAllFilesLoading ? 'opacity-50' : ''
        ]"
        @click="onDownloadClick"
      >
        Download
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleItems: {
      type: Array,
      default: () => []
    },
    year: {
      type: String,
      default: 'actual'
    },
    filesList: {
      type: Array,
      default: () => []
    },
    downloadAllFilesLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortYear: this.year,
      yearOptions: [
        { value: '2024', label: '2024', isSelected: true },
        { value: '2023', label: '2023' },
        { value: '2022', label: '2022' },
        { value: '2021', label: '2021' },
        { value: '2020', label: '2020' },
        { value: '2019', label: '2019' },
        { value: '2018', label: '2018' },
        { value: 'all', label: 'Alles' }
      ]
    }
  },
  computed: {
    downloadFilesDisabled() {
      return !this.filesList.find(item => item.isSelected)
    }
  },
  methods: {
    changeYear() {
      this.$emit('changeYear', this.sortYear)
    },
    onDownloadClick() {
      if (this.downloadAllFilesLoading) return
      this.$emit('onDownloadSelectedClick')
    }
  }
}
</script>

<style>
.download-button {
  height: 34px;
  size: 14px;
  font-weight: 700;
  width: 90px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0px;
}

.download-disabled {
  border: none;
  background-color: var(--vme-light-gray) !important;
}
</style>
